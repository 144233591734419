import React from 'react';
import styled from 'styled-components';
import { PRIMARY_BG_COLOR, PRIMARY_BUTTON_COLOR, PRIMARY_TEXT_COLOR, PRIMARY_TITLE_COLOR, TITLE_FONT } from '../../constants';

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    padding: ${({ firstChild }) => (firstChild ? '0 50px 0 50px' : '90px 50px 0 50px')};
    justify-content: center;
    align-items: center;
    background-color: ${({ color }) => (color ? color : '#000')};
    height: 100vh;

    @media (max-width: 768px) {
        padding: ${({ firstChild }) => (firstChild ? '0 16px 0 16px' : 'padding: 90px 16px 0 16px;')};
    }
`;

const Content = styled.div`
    display: flex;
    width: 100%;
    gap: 5%;
    max-width: 1555px;

    @media (max-width: 1000px) {
        gap: 0;
        flex-direction: ${({ seeMore }) => (seeMore ? 'column' : 'row')};
    }
`;

const Col = styled.div`
    min-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 1000px) {
        min-width: unset;
    }
`;

const Image = styled.img`
    max-width: 1250px;
    max-height: 80vh;
`;

const Title = styled.h1`
    color: ${({ color }) => (color ? color : PRIMARY_TEXT_COLOR)};
    font-family: ${TITLE_FONT};
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 48px;
    word-break: break-word;

    @media (max-width: 1000px) {
        text-align: ${({ seeMore }) => (seeMore ? 'center' : 'left')};
    }

    @media (max-width: 768px) {
        font-size: 32px;
    }
`;

const Tag = styled.div`
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 12px;
    background-color: ${({ bgColor }) => (bgColor ? bgColor : PRIMARY_TEXT_COLOR)};
    border-radius: 8px;
    color: ${({ color }) => (color ? color : '#000')};
    font-family: ${TITLE_FONT};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    width: fit-content;
`;

const TagsWrapper = styled.div`
    display: flex;
    min-width: 193px;
    gap: 16px;
    flex-wrap: wrap;
    margin-bottom: 48px;
`;

const LinKWrapper = styled.a`
    display: flex;
    gap: 8px;
    color: ${({ color }) => (color ? color : PRIMARY_TEXT_COLOR)};
    cursor: pointer;
    font-family: ${TITLE_FONT};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    &:hover {
        text-decoration: none;
        color: ${({ color }) => (color ? color : PRIMARY_TEXT_COLOR)};
    }
`;

const SeeAllStudies = styled.a`
    font-family: ${TITLE_FONT};
    color: ${PRIMARY_BG_COLOR};
    background-color: ${PRIMARY_BUTTON_COLOR};
    height: 48px;
    padding: 0 24px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;

    &:hover {
        text-decoration: none;
        color: ${PRIMARY_BG_COLOR};
    }

    @media (max-width: 1000px) {
        display: none;
    }
`;

const SeeAllStudiesMobile = styled.a`
    margin-top: 33px;
    display: none;
    font-family: ${TITLE_FONT};
    color: ${PRIMARY_BG_COLOR};
    background-color: ${PRIMARY_BUTTON_COLOR};
    height: 48px;
    padding: 0 24px;
    font-size: 18px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;

    &:hover {
        text-decoration: none;
        color: ${PRIMARY_BG_COLOR};
    }

    @media (max-width: 1000px) {
        display: flex;
    }
`;

export const NewCaseStudy = ({ image, title, tags, bgColor, url, seeMore, color, firstChild = false }) => {
    return (
        <Wrapper color={bgColor} firstChild={firstChild}>
            <Content seeMore={seeMore}>
                <Col>
                    {seeMore ? (
                        <>
                            <Title seeMore={seeMore}>{title}</Title>
                            <SeeAllStudies href={'/case-studies'}>SEE ALL CASE STUDIES</SeeAllStudies>
                        </>
                    ) : (
                        <>
                            <Title color={color && PRIMARY_TITLE_COLOR} dangerouslySetInnerHTML={{ __html: title }} />
                            <TagsWrapper>
                                {tags.map(tag => (
                                    <Tag bgColor={color} color={bgColor}>
                                        {tag}
                                    </Tag>
                                ))}
                            </TagsWrapper>
                            <LinKWrapper href={url} color={color && PRIMARY_TITLE_COLOR}>
                                Learn more
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 -960 960 960"
                                    width="24px"
                                    fill={color ? PRIMARY_TITLE_COLOR : PRIMARY_TEXT_COLOR}
                                >
                                    <path d="M647-440H160v-80h487L423-744l57-56 320 320-320 320-57-56 224-224Z" />
                                </svg>
                            </LinKWrapper>
                        </>
                    )}
                </Col>
                <Image src={image} alt={'Our work image example'} />
                {seeMore && <SeeAllStudiesMobile href={'/case-studies'}>SEE ALL CASE STUDIES</SeeAllStudiesMobile>}
            </Content>
        </Wrapper>
    );
};
